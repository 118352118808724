<template>
  <div class="messages container">

    <!-- ------------------------------- Modal: Messages for Individual User ------------------------------------------ -->

	<CModal title="User Messages" color="info" :show.sync="userMessagesModal" size="lg">	
		<div slot="header">
			<CButton color="dark" class="mr-3" v-on:click.prevent="userMessagesModal=false">Close</CButton>
			<CButton color="dark" class="mr-3" v-on:click.prevent="serverCall('deleteAllMessagesForUser')">Delete All Messages</CButton>
			<div class="text-center">
				<label for="loadingMsg" v-if="loading">{{loadingMsg}}</label>
				<scale-loader :loading="loading"></scale-loader>
			</div>
			<p class="text-danger" align="left">{{alert}}</p>
		</div>	  
		<div slot="footer">
		</div>

		<div class="form-group row">
			<label class="col-sm-6 font-weight-bold text-left">User: {{userName}}</label>
			<label class="col-sm-6 font-weight-bold text-right">Phone: {{userPhone}}</label>
		</div>

		<!-- <div v-if="userPhone!=''" class="form-group row">
			<label class="col-form-label font-weight-bold ml-4">WhatsApp Link:</label>
			<a v-bind:href="waUrl" class="link-dark ml-3" target="_blank">{{ waUrl }} </a>
		</div> -->

		<OrderMessages ref="orderMessagesRef"
			:orgIdProp=this.orgId
			:userIdProp="userId"
			:orderProp=null
		/>

	</CModal>


    <!-- ---------------------------------------  Message List -------------------------------------------------- -->

	<p class="font-weight-bold" align="center">Messages</p>
	<div class="text-center">
		<label for="loadingMsg" v-if="loading">{{loadingMsg}}</label>
		<scale-loader :loading="loading"></scale-loader>
	</div>
	<p class="text-danger" align="left">{{alert}}</p>
	<vue-good-table ref="messagesTable" 
							:columns="colMessages" 
							:rows="rowMessages" 
							:pagination-options="pagination_options" 
							:selectOptions="{
									enabled: false,
									selectOnCheckboxOnly: false, 
							}"
							:sort-options="{enabled: false,}"
							styleClass="vgt-table bordered condensed"
							@on-cell-click="userSelected" 
						>
			<div slot="selected-row-actions">
				<CButton color="dark" v-on:click.prevent="serverCall('getMessagesForUser')">User Messages</CButton>
			</div>
			<template slot="table-row" slot-scope="props">
				<span v-if="props.column.field == 'userName'">
					<span><a href="#">{{ props.row.userName }}</a></span> 
				</span>
			</template>
	</vue-good-table>

	<main id="main">
	</main>

  </div>
</template>

<script>
import axios from "axios"
import ScaleLoader from "@/views/utils/ScaleLoader"
import { firebaseAuth } from '../../firebaseConfig.js';
import OrderMessages from './OrderMessages.vue'

export default {
	name: 'messages',

	mixins: [
	],

    components: {
        ScaleLoader,
		OrderMessages
	},
		
	data () {
		return {
			orgId: null,

            loading: false,
            loadingMsg: null,
			alert: '',

			userMessagesModal: false,
			curRowSelected: null,
			userId: '',
			userName: '',
			userPhone: '',
			messageList: [],
			//waUrl: '',

			colMessages: [
					{
							label: 'Customer',
							field: 'userName',
							sortable: false,
							html: true,
					},
					{
							label: 'Datetime',
							field: 'created',
							sortable: false,
					},
					{
							label: 'From',
							field: 'source',
							sortable: false,
							formatFn: this.formatFn,
					},
					{
							label: 'Message',
							field: 'text',
							sortable: false,
					},
			],



			rowMessages: [],

            pagination_options: {
                enabled: true,
                mode: 'records',
                perPage: 20,
                position: 'top',
                perPageDropdown: [10, 20, 30, 40],
                dropdownAllowAll: false,
                setCurrentPage: 1,
                nextLabel: 'next',
                prevLabel: 'prev',
                rowsPerPageLabel: 'Rows per page',
                ofLabel: 'of',
                pageLabel: 'page', // for 'pages' mode
                allLabel: 'All',
			},
		}
	},

	computed: {
	},

	mounted(){
		this.orgId = this.$route.params['orgId']
		this.serverCall('getMessages')
	},

	methods: {
		formatFn: function(value) {
			if (value=='B')
				return 'Business'
			else
				return 'Customer'
		},

		// User clicks on 'userName'	
		userSelected(params){	
			this.curRowSelected = params.row.originalIndex
			this.userId = params.row.userId
			this.serverCall('getMessagesForUser')
		},

		openUserMessagesModal(){
			this.userMessagesModal = true
			this.$refs.orderMessagesRef.getCustomerMessages(this.userId, this.orgId)
		},

		// rowChanged(params){	
		// 	// Current selected Row
		// 	if (this.curRowSelected != null){
		// 		// If new row is not same as previous row, uncheck previous row
		// 		if (this.curRowSelected.originalIndex!=params.row.originalIndex)
		// 			this.curRowSelected.vgtSelected = false
		// 	}
		// 	// New selected row
		// 	this.curRowSelected = params.row
		// 	this.userId = this.curRowSelected.userId
		// },

        serverCall(name) {
            var self = this

			firebaseAuth.onAuthStateChanged(user => {
				if (user) {
					user.getIdToken(true).then(idToken => {
						switch(name) {
							case "getMessages":
								self.getMessages(idToken)
								break;

							case "getMessagesForUser":
								self.getMessagesForUser(idToken)
								break;

							case "deleteAllMessagesForUser":
								self.deleteAllMessagesForUser(idToken)
								break;

							default:
								console.log("Unknown function: " + name)
						}
					})
				}
				else {
					self.alert = "Messages - Please login first."
				}
			})
		},

        // Fetch messages for the given org
        getMessages(authToken) {
            var self = this
			self.alert = null

			// Set loading message
			self.loading = true
			self.loadingMsg = "Fetching Messages ..."

			axios({
					method: 'POST',
					baseURL: process.env.VUE_APP_ROOT_API,
					url: '/GetMessages/',
					headers: {
						'Authorization': 'Bearer ' + authToken,
						'OrgId': self.orgId,
						'Content-Type': 'multipart/form-data',
					},
				})
				.then(function (response) {
					if (response.data.err != ''){
						self.alert = response.data.err
					}
					else {
						self.rowMessages = response.data.messageList
					}
					// Reset loading message
					self.loading = false
					self.loadingMsg = null
				})
				.catch(function (error) {
					// Reset loading message
					self.loading = false
					self.loadingMsg = null
					
					self.alert = String(error)
				})
		},

        // Fetch messages for the given User
        getMessagesForUser(authToken) {
            var self = this
			self.alert = null

			// Set loading message
			self.loading = true
			self.loadingMsg = "Fetching All Messages for User"

			const postJson = {'userId': self.userId}

			axios({
					method: 'POST',
					baseURL: process.env.VUE_APP_ROOT_API,
					url: '/GetMessagesForUser/',
					data: postJson,
					headers: {
						'Authorization': 'Bearer ' + authToken,
						'OrgId': self.orgId,
						'Content-Type': 'application/json',
					},
				})
				.then(function (response) {
					if (response.data.err != ''){
						self.alert = response.data.err
					}
					else {
						//self.messageList = response.data.messageList
						self.userName = response.data.userName
						self.userPhone = response.data.userPhone
						//self.waUrl = "https://wa.me/" + self.userPhone.replace(/\s/g, "")
						self.openUserMessagesModal()
					}
					// Reset loading message
					self.loading = false
					self.loadingMsg = null
				})
				.catch(function (error) {
					// Reset loading message
					self.loading = false
					self.loadingMsg = null
					
					self.alert = String(error)
				})
		},
		
        // Delete messages for the current user
        deleteAllMessagesForUser(authToken) {
            var self = this
			self.alert = null

			// Set loading message
			self.loading = true
			self.loadingMsg = "Deleting Messages ..."

			const postJson = {'userId': self.userId}

			axios({
					method: 'POST',
					baseURL: process.env.VUE_APP_ROOT_API,
					data: postJson,
					url: '/DeleteAllMessagesForUser/',
					headers: {
						'Authorization': 'Bearer ' + authToken,
						'OrgId': self.orgId,
						'Content-Type': 'application/json',
					},
				})
				.then(function (response) {
					if (response.data.err != ''){
						self.alert = response.data.err
					}
					else {
						self.userMessagesModal = false
						self.rowMessages.splice(self.curRowSelected, 1)
					}
					// Reset loading message
					self.loading = false
					self.loadingMsg = null
				})
				.catch(function (error) {
					// Reset loading message
					self.loading = false
					self.loadingMsg = null
					
					self.alert = String(error)
				})
		},

	},

}
</script>

